// import React, { Component } from "react"
import React from "react"
import { useState, useEffect } from 'react';
import { ethers } from 'ethers';
import GreedyGeese from './artifacts/contracts/optimisedContract.json';
import './App.css';
import img1 from './assets/NFT-SUPREM-MONEY-VENTE-VII-low.gif';

const NftAddress = "0xfdA3a676cCb614493A1530607a6BCa1a94487492";

const LiStyle = {
  'fontSize': '20px',
  // 'display': 'flex',
  'listStyleImage': "URL('https://forexgang.trading-formation.fr/images/img_bullet.png')",
  'listStylePosition': 'inside',
  'padding': '10px 10px',
  'paddingTop': '10px',
  'paddingRight': '10px',
  'paddingBottom': '10px'
};

function App() {

  const [error, setError] = useState('');
  const [data, setData] = useState({})


  useEffect(() => {
    fetchData();
  }, [])

  async function fetchData() {
    if (typeof window.ethereum !== 'undefined') {
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const contract = new ethers.Contract(NftAddress, GreedyGeese.abi, provider);

      try {
        const contractStateData = await contract.myContractStateData();
        const [
          maxTokensByTxn,
          maxTokens,
          salestate,
          baseUri,
          mintPrice,
          projectWallet
        ] = String(contractStateData).split(',');

        setData({
          maxTokensByTxn: Number(maxTokensByTxn),
          maxTokens: Number(maxTokens),
          salestate: Number(salestate),
          baseUri,
          mintPrice: Number(mintPrice),
          projectWallet
        });
      }
      catch (err) {
        setError(err.message);
      }
    }
  }

  async function mint() {
    if (typeof window.ethereum !== 'undefined') {
      let accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const signer = provider.getSigner();
      const contract = new ethers.Contract(NftAddress, GreedyGeese.abi, signer);
      try {
        let overrides = {
          from: accounts[0],
          value: data.mintPrice.toString()
        }
        const transaction = await contract.mint(
          1, overrides);
        await transaction.wait();
        fetchData();
      }
      catch (err) {
        setError(err.message);
      }
    }
  }

  const mintPriceMessage = (mintPrice) => !isNaN(mintPrice) ?
    `Each FUCK EUR/USD NFT costs ${mintPrice / 10 ** 18} eth(excluding gas fees)`
    : "[Veuillez connecter votre wallet]"

  return (
    <div className="App">
      <div className="container">
        {error && <p>{error}</p>}
        <h1>CRYPTO NFT SUPREM MONEY</h1>
        <div className="banniere">
          <img src={img1} alt="img" />
        </div>
        <p className="cost">{mintPriceMessage(data.mintPrice)}</p>
        <button onClick={mint}> <span role="img" aria-label="click here">👉🏾</span> MINT NFT <span role="img" aria-label="click here">👈🏾</span></button>
        <ul className="list">
          <li style={LiStyle}>Les 100 premières personnes qui rejoindront ce programme auront la possibilité de recevoir le tout premier NFT de Matt.&nbsp;</li>
          <li style={LiStyle}>La possession de ce NFT permet de nombreux avantages tels que :</li>
          <li style={LiStyle}>L'accès aux sessions MasterMind Privée à Miami pour rencontrer et échanger avec Matt</li>
          <li style={LiStyle}>Vous pourrez venir me rencontrer en personne sur Miami pour assister à mes journées de trading&nbsp;</li>
          <li style={LiStyle}>Vous aurez accès à la nouvelle catégorie unique de signaux de trading de Matt jamais partagé.</li>
          <li style={LiStyle}>Vous serez invités également aux journées de découvertes et d'investissements immobilier sur Miami ( 3 à 5 jours )</li>
          <li style={LiStyle}>Vous aurez accès à mes investissements secrets et privés jamais divulgués</li>
          <li style={LiStyle}>Vous aurez accès à tout mon réseaux sur Miami concernant les investissements immobiliers "off-market" pour générer comme dans mon cas jusqu'à plus de 2 millions par ans</li>
          <li style={LiStyle}>Vous aurez accès à mon réseau d'agent immobilier bilingue francais/anglais avec leurs expériences et networking pour être positionné avant tout le monde pour acquérir les biens immobilier les plus recherché par les touristes sur miami pour gerer de gros chiffres.</li>
          <li style={LiStyle}>Vous aurez accès a la logistique : femme de ménage qui gèrent notre parc immobilier , les entreprises qui gère les checkin/checkout, les entreprisent qui gèrent la maintenance extérieure et intérieure de chaque bien, la liste des magasins où l’ont se fournis pour les meubles et electro-manager</li>
          <li style={LiStyle}>Vous aurez accès aux broker et instituts financiers qui prêtent aux non-américain et aux étrangers.</li>
          <li style={LiStyle}>Vous aurez accès à la liste de nos avocats d’affaires qui servent de gage de confiance pour les banque qui prêtent aux entrepreneurs étrangers, la liste de tous les quartier et condominiums qui authorise Airbnb et a sous location courte duré.</li>
          <li style={LiStyle}>Vous serez invité aux événements VIP avec mon cercle privés d'investisseur et associé à Miami&nbsp;</li>
        </ul>
      </div>
    </div>
  );
}

export default App;
